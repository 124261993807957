@font-face {
  font-family: "dukefont";
  src: url("lib/daidrr__-webfont.woff2") format("woff2"), url("lib/daidrri_-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

:root {
  --font-size-large: 30px;
}

html,
body {
  margin: 0 !important;
  position: fixed;
  overflow: hidden;
  background-size: cover;
  background-image: url("https://duke-assets.s3.eu-west-1.amazonaws.com/public/images/background.jpg");
  font-family: "dukefont";
  color: orange;
  width: 100vw !important;
  height: 100vh !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.button {
  padding: 8px 16px;
  font-size: 20px;
  font-family: "dukefont";
  color: white;
  background: #a5a5a5;
  border: none;
  cursor: pointer;
}

.button:hover {
  color: orange;
}

.button__disabled {
  background: gray;
  cursor: unset;
  color: #a3a3a3 !important;
}

.menu-item {
  padding: 8px;
  cursor: pointer;
  font-size: 40px;
  color: lightgray;
  white-space: nowrap;
}

.menu-item:hover {
  color: orange;
}

.big-font-border {
  -webkit-text-stroke-width: 2px;
}

.input {
  width: 100%;
  background: none;
  font-family: "dukefont";
  color: lightgray;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
}

.input-login {
  -webkit-text-stroke-width: 0;
}

.header {
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: var(--font-size-large);
}

.first-header {
  margin-bottom: 8px;
  font-size: var(--font-size-large);
}

.gray {
  color: lightgray;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.7);
}

.spinner {
  background: url("https://duke-assets.s3.eu-west-1.amazonaws.com/public/images/spinner.gif") no-repeat center center;
  background-size: 100%;
  height: 64px;
  width: 64px;
  filter: brightness(120%);
}

.spinner__small {
  height: 24px;
  width: 24px;
}

.link {
  cursor: pointer;

  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  color: orange;

  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.tooltip {
  position: relative;
  display: flex;
  align-items: center;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 256px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 6px;
  padding: 8px;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: center;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -40px;
  left: 50%;

  transform: translate(-50%, -70%);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.move-legend .recharts-legend-wrapper {
  top: -10px !important;
}

.clickable-icon:hover {
  cursor: pointer;
  color: orange;
}
